import React from 'react';
import './App.css';
import MasterLayout from './components/layouts/MasterLayout/MasterLayout';
import AgoraRTC, { AgoraRTCProvider } from 'agora-rtc-react';

// @INFO Redux
import { Provider } from 'react-redux'
import store from './redux/store'

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
AgoraRTC.setLogLevel(2);


function App() {
  return (
    <AgoraRTCProvider client={client}>
      <div className="App">
        <Provider store={store}>
          <MasterLayout />
        </Provider>
      </div>
    </AgoraRTCProvider>
  );
}

export default App;