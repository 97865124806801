import React, { useState, useEffect } from "react";
import {
	LocalUser,
	RemoteUser,
	useIsConnected,
	useJoin,
	useLocalMicrophoneTrack,
	useLocalCameraTrack,
	useLocalScreenTrack,
	usePublish,
	useRemoteUsers,
	useRTCClient
} from "agora-rtc-react";
import { useParams } from "react-router-dom";
import { BiMicrophone, BiMicrophoneOff } from "react-icons/bi";
import { BsCameraVideo, BsCameraVideoOff, BsPeople } from "react-icons/bs";
import { IoCall, IoCallOutline } from "react-icons/io5";
import { MdScreenShare, MdStopScreenShare, MdClose } from "react-icons/md";
import { FaTools, FaCheck, FaTimes } from "react-icons/fa";
import axios from "axios";
import store from "redux/store";
import { marked } from 'marked';
import { api_base_url } from "config/globals";
import "./ChannelPage.scss";
import SubtitleComponent from "components/commons/Subtitle/SubtitleComponent";

export const isGistUrl = (url: string): boolean => {
	return url.includes('gist.github.com');
};

export const getGistId = (url: string): string => {
	const matches = url.match(/gist\.github\.com\/[^/]+\/([a-zA-Z0-9]+)/);
	return matches ? matches[1] : '';
};

const ChannelPage: React.FC = () => {
	const { channelId: initialChannelId } = useParams<{ channelId: string }>();
	const [channelId, setChannelId] = useState<string>(initialChannelId || "");
	const [calling, setCalling] = useState(false);
	const [token, setToken] = useState<string>("");
	const [isTokenValid, setIsTokenValid] = useState(false);
	const [isVerifying, setIsVerifying] = useState(false);
	const isConnected = useIsConnected();
	const client = useRTCClient();

	// Control states
	const [micOn, setMic] = useState(false);
	const [cameraOn, setCamera] = useState(false);
	const [screenShareOn, setScreenShare] = useState(false);
	const [showParticipants, setShowParticipants] = useState(false);
	const [showColabModal, setShowColabModal] = useState(false);
	const [colabUrl, setColabUrl] = useState("");
	const [activeColab, setActiveColab] = useState("");
	const [isHost, setIsHost] = useState(false);

	const appId = "c132a1e1de674fcd90935ea1d6f4d799";
	const userId = store.getState().auth.user?.email;

	// Tracks
	const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
	const { localCameraTrack } = useLocalCameraTrack(cameraOn);
	const { screenTrack } = useLocalScreenTrack(screenShareOn, {}, "disable");

	// Embed
	const [contentType, setContentType] = useState<'colab' | 'gist' | null>(null);

	useJoin(
		{
			appid: appId,
			channel: channelId!,
			uid: userId,
			token: token || null,
		},
		calling && isTokenValid
	);

	// Token Generation
	useEffect(() => {
		const generateToken = async () => {
			if (!channelId) return;
			setIsVerifying(true);
			try {
				const response = await axios.get(
					`${api_base_url}/agora-token/rtcToken`,
					{
						params: { channelName: channelId },
						headers: {
							Authorization: `Bearer ${store.getState().auth.token}`,
						},
					}
				);
				setToken(response.data.token);
				setIsTokenValid(true);
			} catch (error) {
				console.error("Error generating token:", error);
				setIsTokenValid(false);
			} finally {
				setIsVerifying(false);
			}
		};
		generateToken();
	}, [channelId]);

	// Publish tracks
	usePublish([
		localMicrophoneTrack,
		screenShareOn && !localCameraTrack ? null : localCameraTrack,
		screenShareOn ? screenTrack : null,
	]);

	const remoteUsers = useRemoteUsers();

	useEffect(() => {
		if (isConnected && remoteUsers.length === 0) {
			setIsHost(true);
		}
	}, [isConnected, remoteUsers.length]);

	const handleColabSubmit = () => {
		if (colabUrl) {
			const type = isGistUrl(colabUrl) ? 'gist' : 'colab';
			setContentType(type);
			setActiveColab(colabUrl);

			setShowColabModal(false);
		}
	};

	const handleScreenShare = async () => {
		setScreenShare((prev) => !prev);
	};

	const getUserListClassName = () => {
		const totalUsers = remoteUsers.length + 1;
		if (totalUsers === 1) return "user-list single";
		if (totalUsers === 2) return "user-list two";
		return "user-list many";
	};

	const formatTime = () => {
		const date = new Date();
		return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
	};

	const handleLeaveChannel = async () => {
		setCalling(false);
		setToken(""); 
		setIsTokenValid(false);
		setActiveColab("");
		setContentType(null);
		setColabUrl("");
		setShowParticipants(false); 

		if (client) {
			try {
				// Unpublish local tracks
				await client.unpublish();

				// Leave the channel
				await client.leave();

				console.log("Successfully left the channel");
			} catch (error) {
				console.error("Failed to leave the channel:", error);
			}
		}
	};

	if (!isConnected) {
		return (
			<div className="join-room">
				<div className="streaming-container">
					<SubtitleComponent>Real-time Class Stream</SubtitleComponent>
				</div>

				<input
					type="text"
					placeholder="Channel Name"
					value={channelId}
					onChange={(e) => setChannelId(e.target.value)}
				/>
				<button
					onClick={() => setCalling(true)}
					disabled={!channelId || !isTokenValid}
				>
					Join Channel
				</button>

				{/* Simplified validation indicator with just icons */}
				{!isTokenValid ? (
					<button
						className="magic-button"
						disabled={isVerifying || !channelId}
					>
						{isVerifying ? (
							"Verifying..."
						) : (
							<>
								<FaTimes style={{ color: 'red' }} />
							</>
						)}
					</button>
				) : (
					<span className="validation-icon">
						<FaCheck style={{ color: 'green' }} />
					</span>
				)}

			</div>
		);
	}

	return (
		<div className="channel-page">
			<div className="room-container">
				<div className="room">
					<div className={getUserListClassName()}>
						<div className="user">
							<LocalUser
								audioTrack={localMicrophoneTrack}
								cameraOn={cameraOn}
								micOn={micOn}
								playAudio={false}
								videoTrack={screenShareOn ? screenTrack : localCameraTrack}
								cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
							>
								<span className="user-name">You{isHost ? " (Host)" : ""}</span>
							</LocalUser>
						</div>
						{remoteUsers.map((user) => (
							<div className="user">
								<RemoteUser
									cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
									key={user.uid}
									user={user}
									playAudio={true}
									playVideo={true}
								>
									<span className="user-name">{user.uid} | {user.hasVideo}</span>
								</RemoteUser>
							</div>
						))}
					</div>
				</div>
				{activeColab && (
					<div className="colab-embed">
						<div className="colab-header">
							<span>{contentType === 'gist' ? 'GitHub Gist' : 'Google Colab'}</span>
							<button
								className="close-button"
								onClick={() => {
									setActiveColab("");
									setContentType(null);
								}}
							>
								<MdClose />
							</button>
						</div>
						<div className="colab-container">
							{contentType === 'gist' ? (
								<GistEmbed gistId={getGistId(activeColab)} />
							) : (
								<iframe
									src={activeColab}
									title="Google Colab"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
								/>
							)}
						</div>
					</div>
				)}
			</div>
			<div className="control-bar-container">
				<div className="control-bar">
					<div className="controls-left">
						<span className="control-time">{formatTime()}</span>
					</div>
					<div className="controls-center">
						<button
							className={`control-button ${micOn ? "active" : ""}`}
							onClick={() => setMic((prev) => !prev)}
						>
							{micOn ? <BiMicrophone /> : <BiMicrophoneOff />}
							<span className="tooltip">
								{micOn ? "Turn off microphone" : "Turn on microphone"}
							</span>
						</button>
						<button
							className={`control-button ${cameraOn ? "active" : ""}`}
							onClick={() => setCamera((prev) => !prev)}
						>
							{cameraOn ? <BsCameraVideo /> : <BsCameraVideoOff />}
							<span className="tooltip">
								{cameraOn ? "Turn off camera" : "Turn on camera"}
							</span>
						</button>
						<button
							className={`control-button ${screenShareOn ? "active" : ""}`}
							onClick={handleScreenShare}
						>
							{screenShareOn ? <MdStopScreenShare /> : <MdScreenShare />}
							<span className="tooltip">
								{screenShareOn ? "Stop sharing" : "Share screen"}
							</span>
						</button>
						<button
							className="control-button end-call"
							onClick={handleLeaveChannel}
						>
							{calling ? <IoCall /> : <IoCallOutline />}
							<span className="tooltip">Leave call</span>
						</button>
					</div>
					<div className="controls-right">
						<button
							className={`control-button ${showParticipants ? "active" : ""}`}
							onClick={() => setShowParticipants((prev) => !prev)}
						>
							<BsPeople />
							<span className="tooltip">Show participants</span>
						</button>
						<button
							className="control-button"
							onClick={() => setShowColabModal(true)}
						>
							<FaTools />
							<span className="tooltip">Open tools</span>
						</button>
					</div>
				</div>
			</div>
			<div className={`participants-panel ${showParticipants ? "open" : ""}`}>
				<div className="panel-header">
					<h3>Participants ({remoteUsers.length + 1})</h3>
					<button
						className="close-button"
						onClick={() => setShowParticipants(false)}
					>
						<MdClose />
					</button>
				</div>
				<div className="participants-list">
					<div className="participant-item">
						<div className="participant-avatar">
							{userId?.charAt(0).toUpperCase()}
						</div>
						<div className="participant-info">
							<div className="participant-name">You {isHost ? "(Host)" : ""}</div>
							<div className="participant-status">
								{micOn ? "Mic on" : "Mic off"} • {cameraOn ? "Camera on" : "Camera off"}
							</div>
						</div>
					</div>
					{remoteUsers.map((user) => (
						<div key={user.uid} className="participant-item">
							<div className="participant-avatar">
								{user.uid.toString().charAt(0).toUpperCase()}
							</div>
							<div className="participant-info">
								<div className="participant-name">{user.uid}</div>
								<div className="participant-status">
									{user.hasAudio ? "Mic on" : "Mic off"} •{" "}
									{user.hasVideo ? "Camera on" : "Camera off"}
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			{showColabModal && (
				<div className="colab-modal">
					<div className="modal-header">
						<h3>Add Google Colab Notebook</h3>
						<button
							className="close-button"
							onClick={() => setShowColabModal(false)}
						>
							<MdClose />
						</button>
					</div>
					<div className="modal-content">
						<p>Enter the URL of your Google Colab notebook or GitHub Gist:</p>
						<input
							type="text"
							placeholder="https://colab.research.google.com/... or https://gist.github.com/..."
							value={colabUrl}
							onChange={(e) => setColabUrl(e.target.value)}
						/>
					</div>
					<div className="modal-buttons">
						<button
							className="cancel"
							onClick={() => setShowColabModal(false)}
						>
							Cancel
						</button>
						<button
							className="submit"
							onClick={handleColabSubmit}
							disabled={!colabUrl}
						>
							Add Notebook
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default ChannelPage;

interface GistEmbedProps {
	gistId: string;
}

const GistEmbed: React.FC<GistEmbedProps> = ({ gistId }) => {
	const [gistContent, setGistContent] = useState<string>('');

	useEffect(() => {
		const fetchGist = async () => {
			try {
				const response = await fetch(`https://api.github.com/gists/${gistId}`);
				const data = await response.json();
				const filename = Object.keys(data.files)[0];
				const content = data.files[filename].content;
				setGistContent(content);
			} catch (error) {
				console.error('Error fetching gist:', error);
				setGistContent('Error loading gist content');
			}
		};
		fetchGist();
	}, [gistId]);

	return (
		<div className="notebook-container">
			{gistContent && <NotebookViewer content={gistContent} />}
		</div>
	);
};

interface NotebookCell {
	cell_type: 'markdown' | 'code';
	metadata: any;
	source: string[];
	execution_count?: number | null;
	outputs?: any[];
}

interface NotebookData {
	cells: NotebookCell[];
}

interface CellRendererProps {
	cell: NotebookCell;
}

const CellRenderer: React.FC<CellRendererProps> = ({ cell }) => {
	const source = Array.isArray(cell.source)
		? cell.source.join('')
		: (cell.source as string); // Explicitly assert as string
	const [markdownContent, setMarkdownContent] = useState<string>('');

	useEffect(() => {
		const parseMarkdown = async () => {
			try {
				const parsed = await marked.parse(source);
				setMarkdownContent(parsed);
			} catch (error) {
				console.error("Error parsing Markdown:", error);
				setMarkdownContent(`<p>Error rendering Markdown.</p>`); // Fallback in case of error
			}
		};

		if (cell.cell_type === 'markdown') {
			parseMarkdown();
		}
	}, [cell.cell_type, source]); // React to changes in cell type or source

	if (cell.cell_type === 'markdown') {
		return (
			<div
				className="markdown-cell"
				dangerouslySetInnerHTML={{ __html: markdownContent }}
			/>
		);
	}

	if (cell.cell_type === 'code') {
		return (
			<div className="code-cell">
				<pre>
					<code>{source}</code>
				</pre>
				{cell.outputs && <OutputRenderer outputs={cell.outputs} />}
			</div>
		);
	}

	return null;
};

const OutputRenderer: React.FC<{ outputs: any[] }> = ({ outputs }) => {
	return (
		<div className="cell-output">
			{outputs.map((output, index) => {
				if (output.output_type === 'stream') {
					return <pre key={index}>{output.text}</pre>;
				}
				return null;
			})}
		</div>
	);
};

const NotebookViewer: React.FC<{ content: string }> = ({ content }) => {
	const notebook: NotebookData = JSON.parse(content);

	return (
		<div className="notebook-viewer">
			{notebook.cells.map((cell, index) => (
				<CellRenderer key={index} cell={cell} />
			))}
		</div>
	);
};