import React from 'react'
import stepsHelp from './steps/index'
import store from 'redux/store'

type ITypesScreensHelpSteps =
  'home' |
  'laboratories' |
  'previewLaboratory'

class HelpStepsService {

  private storeObject = store

  /**
   * @INFO Renderizar el modulo de ayuda
   * @param screen
   */
  public renderHelpModule = async (queryStep: ITypesScreensHelpSteps) => {
    // Revisar si esta permitido mostrar el componente
    const allowed = true
    if (allowed) {
      // Obtener los steps para el componente
      const steps = this.getStepsFromScreen(queryStep)

      // Dynamically import HelpStepsComponent
      const { default: HelpStepsComponent } = await import(
        'components/modules/helpSteps/containers/helpStepsComponent'
      );

      return (
        <HelpStepsComponent
          steps={steps}
          type='floating'
          fixed
          position={{
            vertical: 'bottom',
            horizontal: 'end'
          }}
          styleJoyride={{
            buttonNext: {
              backgroundColor: 'var(--primary)',
              borderRadius: '0px 25px 25px 0px',
              padding: '0.7rem',
              width: '8rem'
            },
            buttonBack: {
              backgroundColor: 'var(--primary2)',
              color: 'white',
              marginRight: '0px',
              padding: '0.7rem',
              borderRadius: '25px 0px 0px 25px',
              width: '8rem'
            },
            buttonSkip: {
              borderRadius: '25px',
              padding: '0.7rem',
              color: '#979797',
              backgroundColor: '#DADADA'
            },
            buttonClose: {
              border: '1px solid #979797',
              borderRadius: '50%',
              padding: '6px',
              width: '0.6rem',
              height: '0.6rem',
              marginRight: '0.5rem',
              marginTop: '0.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#979797'
            },
            tooltip: {
              borderRadius: '15px'
            }
          }}
        />
      )
    } else {
      return null
    }
  }

  /**
   * @INFO Obtener los pasos según la query
   * @param queryStep
   * @returns
   */
  private getStepsFromScreen = (queryStep: ITypesScreensHelpSteps) => {
    // @ts-ignore
    let response: IStepHelpSteps[] | undefined = stepsHelp[queryStep]
    if (!response) response = []
    return response
  }

  /**
   * @INFO Saber si debo mostrar o no el componente desde el lugar donde lo llaman
   * @param queryStep
   */
  // private getPermissionState = (queryStep: ITypesScreensHelpSteps) => {
  //   const nameModule = 'system_lms:help_module'
  //   let response: boolean = false
  //   const modules = this.storeObject.getState().auth.user?.alliance?.module_configuration
  //   if(modules?.length){
  //     const helpModule = modules.find(item => item.module_name === nameModule)
  //     if(helpModule){
  //       const screensAllowed: string[] = helpModule.config?.screens
  //       if(screensAllowed && screensAllowed.length){
  //         const exist = screensAllowed.find(item => item === queryStep)
  //         if(exist){
  //           response = true
  //         }
  //       }
  //     }
  //   }
  //   return response
  // }

}

export default HelpStepsService