import { SystemConfig, TypeModulesSystem } from "types/app/app.actions.types";
import * as AppActions from 'redux/reducers/app/app.actions';
import systemConfigs from 'config/system';
import store from "redux/store";
import { custom_system_type } from "config/globals";
import axios from "axios";

class AppService {

    private storeObject = store;

    /**
     * @INFO Hacer pausa asíncrona
     * @param ms 
     * @returns 
     */
    public asyncPause = (ms: number) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => resolve(true), ms);
        });
    }

    /**
     * @INFO Verificar si un modulo esta disponible para mostrar según la configuración del sistema
     * @param module 
     */
    public validateCustomConfigModule = (module: TypeModulesSystem) => {
        const config = this.getSystemConfig();
        const systemType = this.storeObject.getState().app.systemType;
        if (systemType === 'custom') {
            if (config && config?.modules?.includes(module)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    /**
     * @INFO Obtener la configuración del sistema
     * @returns 
     */
    public getSystemConfig = () => {
        let systemConfig = this.storeObject.getState()?.app?.systemConfig;
        if (!systemConfig) {
            systemConfig = this.loadSystemConfig();
        }
        return systemConfig;
    }

    /**
     * @INFO Establecer la configuración de la aplicación
     */
    public loadSystemConfig = (): SystemConfig | undefined => {
        const host = window.location.hostname;
        const prefixHost = host.split('.vlesim.')[0];
        if (custom_system_type && prefixHost && (custom_system_type as any)[prefixHost] && (custom_system_type as any)[prefixHost].key) {
            const key = (custom_system_type as any)[prefixHost].key;
            const config = (systemConfigs as any)[key];
            this.storeObject.dispatch(AppActions.updateSystemConfigAction(config));
            this.storeObject.dispatch(AppActions.updateSystemTypeAction('custom'));
            return config;
        }
    }

    /**
     * @INFO Obtener la ip del cliente
     */
    public getIpClient = async (): Promise<string> => {
        const res = await axios.get('https://geolocation-db.com/json/')
        return res.data.IPv4
    }

    /**
     * @INFO Encriptar datos para enviar al servidor
     * @param data 
     */
    public encryptData = async (data: string, publicKey: string): Promise<string> => {
        try {
            // Convert the public key to a format suitable for the Web Crypto API
            const publicKeyJwk = {
                kty: "RSA",
                n: publicKey, // Replace with your actual public key components
                e: "AQAB",
                alg: "RSA-PKCS1-v1_5",
                ext: true,
            };

            const key = await window.crypto.subtle.importKey(
                "jwk",
                publicKeyJwk,
                {
                    name: "RSA-PKCS1-v1_5",
                    hash: { name: "SHA-256" },
                },
                false,
                ["encrypt"]
            );

            const encodedData = new TextEncoder().encode(data);
            const encryptedData = await window.crypto.subtle.encrypt(
                {
                    name: "RSA-PKCS1-v1_5",
                },
                key,
                encodedData
            );

            return btoa(String.fromCharCode(...new Uint8Array(encryptedData)));
        } catch (error) {
            console.error("Encryption failed:", error);
            return ""; // Or handle the error appropriately
        }
    };
}

export default AppService;