import { GameConfig } from 'types/app/app.types';

// Exponer variables del env json
export const environment = process.env.NODE_ENV;
const env =
  environment === 'development'
    ? require('./env.json')
    : require('./env.prod.json');
export const base_url =
  environment === 'production'
    ? env?.main_api?.prod
      ? env.main_api.prod
      : undefined
    : env?.main_api?.dev
    ? env.main_api.dev
    : undefined;
export const socket_url =
  environment === 'production'
    ? env?.main_socket?.prod
      ? env.main_socket.prod
      : undefined
    : env?.main_socket?.dev
    ? env.main_socket.dev
    : undefined;
export const HOME_ROUTE = env?.home_route ? env.home_route : '/';
export const DASHBOARD_SITE = env?.dashboards_site ? env.dashboards_site : '';
export const custom_system_type = env?.custom_system_type
  ? env.custom_system_type
  : undefined;
export const GAME_CONFIG: GameConfig = env?.game ? env.game : {};

// API Base url
export const api_base_url = 'https://core-backend.vlesim.com/api';